import React from "react";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import Page from "../components/Page";

const CookiesPolicy = () => {
  const { t } = useTranslation();

  return (
    <Page title="Cookies policy" description="Cookies policy">
      <Container>
        <section className="section" id="cookies-policy">
          <h3>{t("legal-section.cookies-policy-page.title")}</h3>

          <p>
            {t("legal-section.cookies-policy-page.introduction-paragraph-one")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.introduction-paragraph-two")}
          </p>

          <p>
            {t(
              "legal-section.cookies-policy-page.introduction-paragraph-three"
            )}
          </p>

          <h6>{t("legal-section.cookies-policy-page.what-title")}</h6>

          <p>{t("legal-section.cookies-policy-page.what-content")}</p>

          <h6>{t("legal-section.cookies-policy-page.how-title")}</h6>

          <p>
            {t("legal-section.cookies-policy-page.how-content-paragraph-one")}
          </p>

          <ul>
            <li>
              {t("legal-section.cookies-policy-page.how-content-item-one")}
            </li>
            <li>
              {t("legal-section.cookies-policy-page.how-content-item-two")}
            </li>
            <li>
              {t("legal-section.cookies-policy-page.how-content-item-three")}
            </li>
            <li>
              {t("legal-section.cookies-policy-page.how-content-item-four")}
            </li>
            <li>
              {t("legal-section.cookies-policy-page.how-content-item-five")}
            </li>
            <li>
              {t("legal-section.cookies-policy-page.how-content-item-six")}
            </li>
            <li>
              {t("legal-section.cookies-policy-page.how-content-item-seven")}
            </li>
          </ul>
          <p>
            {t("legal-section.cookies-policy-page.how-content-paragraph-two")}
          </p>

          <h6>{t("legal-section.cookies-policy-page.types-cookies")}</h6>

          <p className="mt-2">
            {t("legal-section.cookies-policy-page.types-cookies-paragraph-1")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.types-cookies-paragraph-2")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.types-cookies-paragraph-3")}
          </p>

          <p className="mt-2">
            {t("legal-section.cookies-policy-page.types-cookies-paragraph-4")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.types-cookies-paragraph-5")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.types-cookies-paragraph-6")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.types-cookies-paragraph-7")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.types-cookies-paragraph-8")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.types-cookies-paragraph-9")}
          </p>

          <p className="mt-2">
            {t("legal-section.cookies-policy-page.types-cookies-paragraph-10")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.types-cookies-paragraph-11")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.types-cookies-paragraph-12")}
          </p>

          <p className="mt-2">
            {t("legal-section.cookies-policy-page.types-cookies-paragraph-13")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.types-cookies-paragraph-14")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.types-cookies-paragraph-15")}
          </p>

          <table className="table">
            <thead>
              <tr>
                <th>Cookie ID</th>
                <th>Type</th>
                <th>Expiry</th>
                <th>Domain</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>

          <p>
            {t("legal-section.cookies-policy-page.types-cookies-paragraph-16")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.types-cookies-paragraph-17")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.types-cookies-paragraph-18")}
          </p>

          <h6>{t("legal-section.cookies-policy-page.how-to-manage")}</h6>

          <p>{t("legal-section.cookies-policy-page.how-to-manage-content")}</p>

          <h6>{t("legal-section.cookies-policy-page.how-to-authorization")}</h6>

          <p>
            {t(
              "legal-section.cookies-policy-page.how-to-authorization-content"
            )}
          </p>

          <h6>{t("legal-section.cookies-policy-page.how-to-consent")}</h6>

          <p>
            {t("legal-section.cookies-policy-page.how-to-consent-paragraph1")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.how-to-consent-paragraph2")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.how-to-consent-paragraph3")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.how-to-consent-paragraph4")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.how-to-consent-paragraph5")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.how-to-consent-paragraph6")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.how-to-consent-paragraph7")}
          </p>

          <h6>{t("legal-section.cookies-policy-page.updating-cookies")}</h6>

          <p>
            {t("legal-section.cookies-policy-page.updating-cookies-paragraph1")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.updating-cookies-paragraph2")}
          </p>

          <p>
            {t("legal-section.cookies-policy-page.updating-cookies-paragraph3")}
          </p>
        </section>
      </Container>
    </Page>
  );
};

export default CookiesPolicy;
